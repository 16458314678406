import $ from 'jquery'

const $doc = $(document)

function scrollToSection(event) {
  const el = $(event.currentTarget);
  let section = el.attr('href');
  event.preventDefault();

  if (section) {
    $('html, body').animate({
      scrollTop: $(section).offset().top
    }, 1000);
  }
}

export default function initScrollTo(){
  $doc.on('click', '.js-scroll-to', scrollToSection )
  $doc.on('click', '.js-scroll-to a', scrollToSection )
}
