import $ from 'jquery';
import 'slick-carousel';

var $slickGreen = false;

function initLogoSlider(){
	$('.js-logo-slider').slick({
		dots: false,
		infinite: false,
		arrows: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		pauseOnHover: false,
		assesibilitiy: true,
		mobileFirst: true,
		responsive: [
			{
				breakpoint: 960,
				settings: "unslick"
			},
			{
				breakpoint: 700,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
				}
			}
		]
	});

	$(window).resize(function () {
		if ($(window).width() > 960){
			if ($slickGreen) {
				$('.js-logo-slider').slick('unslick')
				$slickGreen = false;
			}
		}
		else{
			if (!$slickGreen) {
				$('.js-logo-slider').slick({
					dots: false,
					infinite: false,
					arrows: true,
					slidesToShow: 1,
					slidesToScroll: 1,
					pauseOnHover: false,
					assesibilitiy: true,
					mobileFirst: true,
					responsive: [
						{
							breakpoint: 960,
							settings: "unslick"
						},
						{
							breakpoint: 700,
							settings: {
								slidesToShow: 2,
								slidesToScroll: 1,
							}
						}
					]
				});
				$slickGreen = true;
			}
		}
	});
}

export default function initLogoSliderFn(){
  $(document).ready( initLogoSlider )
}
