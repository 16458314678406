import $ from 'jquery'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

function fadeInSimple(elem, delay, duration) {
  gsap.to(elem, {
    duration: duration,
    y: 0,
    autoAlpha: 1,
    ease: "power1.out",
    stagger: 0.3,
    delay: delay,
    overwrite: 'auto'
  })
}

function fadeInElement(elem, delay, time) {
  hide(elem) // assure that the element is hidden when scrolled into view
  ScrollTrigger.create({
    trigger: elem,
    onEnter: function() {
      fadeInSimple(elem, delay, time)
    }
  })
}

function hide(elem) {
  gsap.set(elem, {
    autoAlpha: 0,
    y: 10
  })
}

function initHeader(){
  gsap.to($(".js-header"), {
    duration: 1.5,
    delay: 0,
    y: 0,
    onComplete: function() {
      $(".js-header").addClass('header--transition');
    }
  })
}

function init() {
  gsap.registerPlugin(ScrollTrigger)
  initHeader();
  $(".js-fadein").each(function() { fadeInElement($(this), "0.3", "0.7") });
  $(".js-fadein-slow").each(function() { fadeInElement($(this), "0.6", "1") });
}

export default function initGreenSock() {
  $(document).ready(init)
}
