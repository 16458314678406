import $ from 'jquery'

const $doc = $(document)

function toggleAccItem() {
  let el = $(this);
  if ( el.parent().parent().hasClass('active') ) {
    el.attr('aria-expanded', 'false');
  } else {
    el.attr('aria-expanded', 'true');
  }
  el.parent().next().slideToggle('fast');
  el.parent().parent().toggleClass('active');
}


export default function toggleAccordionItem(){
  $(document).ready( 
    $doc.on('click', '.js-accordion-item button', toggleAccItem )
   )
}