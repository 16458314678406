import $ from 'jquery'
const $doc = $(document)
import { getCookie, setCookie } from '/lib/cookies'

function openPopup(){
    var hasCookie = getCookie('newsletter-cookie');
    setTimeout(function(){
        if(!hasCookie) $(".popup-notice").addClass('active');
    }, 10000)
}

function closePopupNotice(){
    $(".popup-notice").removeClass('active');
    setCookie('newsletter-cookie', 'true', 1);
}

export default function initPopupNewsletter() {
    $doc.on('click', '.popup-notice__close', closePopupNotice)
    $(document).ready( openPopup )
}
